<script>
import { required, email } from "vuelidate/lib/validators";

import { authMethods, notificationMethods } from "@/state/helpers";

export default {
  data() {
    return {
      userRole: "admin",
      email: "@example.com",
      password: "Pass@123",
      submitted: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      // this.$v.$touch();

      // if (this.$v.$invalid) {
      //   return;
      // } else {
      const { userRole, email, password } = this;
      if (userRole && email && password) {
        this.logIn({ email: `${userRole}${email}`, password });
      }
      // }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <h4 class="font-size-18 mt-4">Welcome Back!</h4>
                        <p class="text-muted">Sign in to continue.</p>
                      </div>

                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                        >{{ notification.message }}</b-alert
                      >

                      <div class="p-2 mt-5">
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogIn"
                        >
                          <label class="card-radio-label mb-3">
                            <input
                              type="radio"
                              name="user-role"
                              id="user-role"
                              v-model="userRole"
                              value="admin"
                              class="card-radio-input"
                            />

                            <div class="card-radio">
                              <span>Paymint Admin</span>
                            </div>
                          </label>
                          <label class="card-radio-label mb-3">
                            <input
                              type="radio"
                              name="user-role"
                              id="user-role"
                              v-model="userRole"
                              value="company"
                              class="card-radio-input"
                            />

                            <div class="card-radio">
                              <span>Company Admin</span>
                            </div>
                          </label>
                          <label class="card-radio-label mb-3">
                            <input
                              type="radio"
                              name="user-role"
                              id="user-role"
                              v-model="userRole"
                              value="employee"
                              class="card-radio-input"
                            />

                            <div class="card-radio">
                              <span>Employee</span>
                            </div>
                          </label>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>